import React, { useState, useEffect } from 'react';
import SearchableDropdown from '../TicketComponents/SearchableDropdown';
import { useApiClient } from '../../service/API';
import '../../styling/Dashboard.css';

const Dashboard = () => {
  const [userEmail, setUserEmail] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [type, setType] = useState('month');
  const [loading, setLoading] = useState(false);
  const apiClient = useApiClient();
  const [userList, setUserList] = useState([]);

  useEffect(()=>{
    fetchUserList();
  },[])

  const fetchUserList = async () => {
    try {
      const userListGet = await apiClient.get(`/users/bouser-list`);
      const all = {
        email: "",
        id: "",
        fullname: "All"
      };

      // Adding the "All" entry at the beginning of the list
      const userListWithAll = [all, ...userListGet.data];

      // Setting the list state with the new list
      setUserList(userListWithAll);
    } catch (error) {
      // console.error(error, 'Error fetching user list. Please contact IT support.');
    }
  };

  // Handler to submit filter form
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await apiClient.get('/export/excel', {
        params: {
          type: type,
          startDate: startDate,
          endDate: endDate,
          userEmail: userEmail,
        },
        responseType: 'blob', // Ensure the response is treated as a blob
      });

      // Create a temporary link to trigger the download
      const blob = response.data;
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'data.xlsx'; // Set the default file name
      link.click(); // Programmatically trigger the download

    } catch (error) {
      // console.error("There was an error fetching the job stats", error);
    } finally {
      setLoading(false);
    }
  };

  const handleEndDateChange = (e) => {
    const newEndDate = e.target.value;
    if (startDate && newEndDate < startDate) {
      // If the selected end date is before the start date, do not update
      alert("End date cannot be earlier than Start date.");
    } else {
      setEndDate(newEndDate);
    }
  };

  const handleSelectionChange = (selectedEmail) => {
    setUserEmail(selectedEmail);
  };

  return (
    <div className="dashboard">
      <h1>User Performance Report</h1>

      <form onSubmit={handleSubmit}>
        <div>
            <label>Email:</label>
            <SearchableDropdown
                options={userList}
                selectedValue={userEmail}
                onSelectedChange={handleSelectionChange}
                placeholder="Select user"
            />
        </div>
        <div>
          <label>Start Range Of Created Date:</label>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
        <div>
          <label>End Range Of Created Date:</label>
          <input
            type="date"
            value={endDate}
            onChange={handleEndDateChange}
            disabled={!startDate}
          />
        </div>
        <div>
          <label>Type:</label>
          <select value={type} onChange={(e) => setType(e.target.value)}>
            <option value="day">Week</option>
            <option value="month">Month</option>
            <option value="year">Year</option>
          </select>
        </div>

        <button type="submit" disabled={loading}>
          {loading ? 'Loading...' : 'Export Excel Report'}
        </button>
      </form>
    </div>
  );
};

export default Dashboard;
