import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { ACCESS_TOKEN } from "../constants";

const ProtectedRoute = ({ userRoles, roles, children }) => {
  const [loading, setLoading] = useState(true);
  const [authorized, setAuthorized] = useState(false);
  const token = localStorage.getItem(ACCESS_TOKEN);

  useEffect(() => {
    const checkAuthStatus = async () => {
      if (token && userRoles) {
        // User is authenticated, check roles
        const hasRequiredRoles = roles.length === 0 || roles.some(role => userRoles.includes(role));
        await setAuthorized(hasRequiredRoles);
      } else {
        // User is not authenticated
        await setAuthorized(false);
      }
      setLoading(false);
    };

    checkAuthStatus();
  }, [token, userRoles, roles]);

  // Show a loading spinner or placeholder while checking auth status
  if (loading) {
    return <div>Loading...</div>; // Adjust as needed (e.g., use a spinner component)
  }

  // Handle unauthorized access
  if (!authorized) {
    if(userRoles.length === 1 && userRoles.includes("USER") ){
      const message = "Your account does not have the necessary permissions. Please contact admin to provide the permission.";
      return <Navigate to="/Unauthorized" state={{ message }} />;
    }
    return <Navigate to="/not-found" />;
  }

  // Render children if authorized
  return children;
};

export default ProtectedRoute;
